export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
        "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find"])},
        "initialLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial Lines"])},
        "displayLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Lines"])},
        "autoTail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Tail"])},
        "stopAutoTail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Tail"])},
        "cachedLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cached Lines"])},
        "cachedPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cached Pages"])},
        "linesPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lines per page"])},
        "redLinesExplain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The first line in a page is in RED."])},
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tab - view by page"])},
        "overFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are more than 10,000 lines in cache, disconnected to server, you can continue to view the content in cache."])},
        "overFlowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache overflow"])},
        "connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecting"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing"])},
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
        "confirmToClearLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to clear the lines displayed, do you confirm ?"])},
        "succeedInClear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succeed in clear logs."])},
        "failedToKill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to kill process."])},
        "killed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succeeded in kill process."])},
        "confirmToKill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is going to kill the ansible task running in the background, may introduce unexpected effect, do you confirm to continue?"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I do confirm to kill the task."])},
        "forceKill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force to kill the task"])},
        "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Completed"])}
      },
      "zh": {
        "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日志"])},
        "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查 找"])},
        "initialLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初始行数"])},
        "displayLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示行数"])},
        "autoTail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动追踪"])},
        "stopAutoTail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂停追踪"])},
        "cachedLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已缓存行数"])},
        "cachedPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已缓存页数"])},
        "linesPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每页行数"])},
        "redLinesExplain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["红色为每页第一行，出现折行时重新计算"])},
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tab键可逐页查看"])},
        "overFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缓存内容已经超过 10000 条，已断开与服务器的连接，您可以继续查看缓存中的内容"])},
        "overFlowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缓存过大"])},
        "connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在连接"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日志追踪进行中"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在关闭"])},
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已断开"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未知状态"])},
        "confirmToClearLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作将清空当前显示的日志, 是否继续？"])},
        "succeedInClear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除日志成功"])},
        "failedToKill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束进程失败"])},
        "killed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功结束进程"])},
        "confirmToKill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将要强制结束此 ansible 进程，有可能会导致不能预测的问题，是否继续？"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我确定要强制结束此任务"])},
        "forceKill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强制结束任务"])},
        "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务已结束"])}
      }
    }
  })
}
